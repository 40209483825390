import Image from "../shared/Image";
import { Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import { useState, useEffect, useCallback, useMemo } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import css from "../../styles_web/products/SimilarProducts.module.css";
import { useUpdateCart, useGetCartBySku } from "../../hooks/useManageCart";
import { getFormattedPrice } from "../../utils";
import { StyledH5, StyledLabelRegular } from "../../styled";

const SimilarProducts = ({ skuSet, selectedSku }) => {
  const alternateProducts = useMemo(() => {
    const selectedProduct = skuSet?.find((item) => item.sku === selectedSku);
    if (selectedProduct) {
      return selectedProduct?.alternateProducts?.filter(
        (product) => product.inventory > 0
      );
    }
    return [];
  }, [skuSet, selectedSku]);

  if (!alternateProducts || alternateProducts.length === 0) return null;

  return (
    <div className={css.container}>
      <Accordion
        defaultExpanded
        elevation={0}
        className={css.accordion}
        disableGutters={true}
        sx={{ backgroundColor: "var(--gray)" }}
      >
        <AccordionSummary
          className={css.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <StyledH5 className={css.title}>View Similar Products</StyledH5>
        </AccordionSummary>
        <AccordionDetails className={css.accordionDetails}>
          {alternateProducts?.map((product) => {
            return <Thumbnail key={product.sku} product={product} />;
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Thumbnail = ({ product }) => {
  const { sku } = product || {};
  const navigate = useNavigate();
  const location = useLocation();

  const { data: cart } = useGetCartBySku(sku);
  const [isInCart, setIsInCart] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const { mutate, isError, isSuccess } = useUpdateCart();
  const handleAdd = () => {
    mutate({
      items: [{ sku, quantity: 1 }],
      itemsRemoved: [],
      rewardPoints: 0,
      couponCode: "",
      shipVia: "",
      shippingRate: "",
      shipName: "",
      discount: 0,
      isPercent: false,
    });
  };

  const handleNavigateToCart = () => {
    navigate(`/cart`, { state: { backgroundLocation: location } });
  };

  const resetError = () => {
    setAlertMsg("");
  };

  const handleViewMore = () => navigate(`/products/${sku}`);

  const handleCartChange = useCallback(() => {
    if (!cart || cart?.length === 0) {
      setIsInCart(false);
    } else {
      setIsInCart(true);
    }
  }, [cart]);

  const handleAlert = useCallback(() => {
    isError
      ? setAlertMsg("Error adding product to cart")
      : isSuccess
        ? setAlertMsg("Item added successfully")
        : setAlertMsg("");
  }, [isError, isSuccess]);

  useEffect(() => {
    handleCartChange();
  }, [handleCartChange]);

  useEffect(() => {
    handleAlert();
  }, [handleAlert]);

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <Box className={css.thumbnail}>
      <Box onClick={handleViewMore} sx={{ cursor: "pointer" }}>
        <Image
          src={product?.thumbnail}
          alt={product?.shortName}
          loading="lazy"
          className={css.thumbnailImage}
        />
      </Box>
      <Box>
        <Box className={css.thumbnailTitleBox}>
          <StyledLabelRegular
            className={css.thumbnailTitle}
            onClick={handleViewMore}
            style={{ cursor: "pointer" }}
          >
            {truncate(product?.shortName, 50)}
          </StyledLabelRegular>
        </Box>
        <Box className={css.sizeAndPriceInfo}>
          <StyledLabelRegular className={css.sizeType}>
            {product?.sizeLabel}
          </StyledLabelRegular>
          <StyledLabelRegular className={css.thumbnailTitle}>
            ${getFormattedPrice(product?.price, 2)}
          </StyledLabelRegular>
        </Box>
        {isInCart ? (
          <Button
            fullWidth
            onClick={handleNavigateToCart}
            className={css.btnStyle}
            variant="btn-secondary-outlined"
          >
            In Your Cart
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={handleAdd}
            className={css.btnStyle}
            variant="btn-secondary-outlined"
          >
            Add To Cart
          </Button>
        )}
      </Box>
      <Snackbar
        onClose={resetError}
        autoHideDuration={4000}
        open={Boolean(alertMsg)}
      >
        <Alert
          onClose={resetError}
          severity={isError ? "error" : "success"}
          componentsProps={{
            closeButton: { sx: { height: "auto" } }
          }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SimilarProducts;
