import css from "../../styles/ProductDetailsHeader.module.css";
import { StyledH1, StyledBodyLight } from "../../styled";

const ProductDetailsHeader = ({ data }) => {
  const { name, disabled, oldName } = data || {};
  return (
    <>
      <StyledH1 className={css.title}>
        {name} {disabled && <b>*</b>}
      </StyledH1>
      {oldName && (
        <StyledBodyLight className={css.oldName}>
          ({`${oldName}`})
        </StyledBodyLight>
      )}
    </>
  );
};

export default ProductDetailsHeader;
