import { useEffect } from "react";
import ReactGA from "react-ga4";

const GoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }, []);

  return null;
};

export default GoogleAnalytics;
