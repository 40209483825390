import Button from "@mui/material/Button";
import { useState, forwardRef } from "react";
import { useMutation } from "react-query";
import { loginUser } from "../../services";
import "../../styles/Cart.css";
import { LOGIN_SUCCESS } from "./Constants";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getIntercomUser } from "../../utils/jwt";
import { useIntercom } from "react-use-intercom";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CartLogin({ createExpressAccount, loginSuccess, loginFail }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { update } = useIntercom();

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const { mutate } = useMutation((data) => loginUser(data), {
    onSuccess: () => {
      loginSuccess(LOGIN_SUCCESS);
      const userDetails = getIntercomUser();
      update(userDetails);
    },
    onError: (error) => {
      loginFail(error?.message);
      setIsError(true);
      setError(error?.message);
      const userDetails = getIntercomUser();
      update(userDetails);
    },
  });

  const resetError = () => {
    setIsError(false);
    setError("");
  };

  const handleLogin = (event) => {
    event.preventDefault();

    mutate({ email: email, password: password });
  };

  const handleEmailChange = (event) => {
    setEmail(event?.target?.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event?.target?.value);
  };

  return (
    <>
      <div className="gy-cart-body">
        <h4 className="gy-cart-display">Sign In</h4>

        <div>
          <form onSubmit={handleLogin}>
            <label htmlFor="signInEmail"></label>
            <input
              className="gy-cart-input"
              autoComplete="on"
              type="email"
              name="signInEmail"
              placeholder="Email Address"
              onChange={handleEmailChange}
              value={email}
            />
            <label htmlFor="signInPass"></label>
            <input
              className="gy-cart-input"
              autoComplete="on"
              type="password"
              name="signInPass"
              placeholder="Password"
              onChange={handlePasswordChange}
              value={password}
            />

            <a className="gy-cart-anchor" href="/forgot-password">
              Forgot your password?
            </a>
            <Button
              disabled={!(email && password)}
              variant="btn-prominent-fluid"
              className="gy-cart-btn"
              type="submit"
            >
              Sign In
            </Button>
            <a className="gy-cart-anchor" href="/register-retail">
              Create an Account
            </a>
            <button className="gy-cart-btn" onClick={createExpressAccount}>
              <b>EXPRESS CHECKOUT</b>
            </button>
          </form>
        </div>
        <Snackbar
          onClose={resetError}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          open={isError}
        >
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default CartLogin;
