import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CartActionButton from "../products/CartActionButton";
import CartList from "./CartList";
import { useGetCart } from "../../hooks/useManageCart";
import {
  CART,
  CART_ITEM_MESSAGE,
  CART_ITEM_MESSAGE_PLURAL,
  CHECKOUT,
  SUB_TOTAL,
} from "./Constants";
import "./CartMain.css";
import { checkLogin } from "../../utils/jwt";
import CartLogin from "./CartLogin";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { getFormattedPrice } from "../../utils";
import Loader from "../Loader";
import RewardsOnOrder from "../checkout/RewardsOnOrder";
import { useRegenRewards } from "../../hooks/useManageRewards";
import OutOfStockDialog from "./OutOfStockDialog";
import { getOutOfStockList } from "../checkout/helper";
import { useCallback } from "react";
import { beginCheckout_GA } from "../../utils/GoogleAnalytics";
import CouponHint from "../checkout/CouponHint";

function CartMain() {
  const isLoggedIn = checkLogin();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const navigate = useNavigate();
  const [showCart, setShowCart] = useState(true);
  const queryClient = useQueryClient();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [outOfStockOpen, setOutOfStockOpen] = useState(false);
  const location = useLocation();

  const { data, isLoading, isError, error } = useGetCart(
    location?.state?.backgroundLocation?.pathname === "/checkout",
      from === "email"
  );
  const { data: rewardsData, isFetching } = useRegenRewards();

  useEffect(() => {
    if (isError) {
      setShowAlert(true);
    }
  }, [isError]);

  const handleEmptyBackgroundPath = useCallback(() => {
    const showLogin = !!(from && !isLoggedIn);
    if (!location?.state) {
      navigate(showLogin ? `/cart?from=${from}` : "/cart", {
        state: {
          backgroundLocation: {
            hash: "",
            key: "default",
            pathname: "/",
            search: "",
            state: null,
          },
        },
      });
    }
    if (showLogin) setShowCart(false);
  }, [location, navigate, from, isLoggedIn, setShowCart]);

  useEffect(() => {
    handleEmptyBackgroundPath();
  }, [handleEmptyBackgroundPath]);

  const outOfStockItems = useMemo(() => {
    if (data) {
      return getOutOfStockList(data?.items);
    }
    return [];
  }, [data]);

  const cartItems = useMemo(() => {
    if (data && data.items) {
      const cartData = data.items.sort((item1, item2) =>
        item1.sku > item2.sku ? 1 : item1.sku < item2.sku ? -1 : 0
      );
      return <CartList cartData={cartData} />;
    }
  }, [data]);

  const handleOutOfStockSuccess = () => {
    beginCheckout_GA(data);
    navigate(`/checkout`);
  };

  if (isLoading || !data) {
    return <Loader />;
  }

  const handleBack = () => {
    if (location?.state?.backgroundLocation?.pathname) {
      const searchPath = location.state.backgroundLocation.search
        ? location.state.backgroundLocation.search
        : "";
      navigate(location.state.backgroundLocation.pathname + searchPath);
    } else {
      navigate(-1);
    }
  };

  const handleBackdropClick = () => {
    handleBack();
  };

  const handleKeyDown = (event) => {
    if (event?.key === "Escape") {
      handleBack();
    }
  };

  const handleExpressAccount = () => {
    navigate("/register-express-retail");
  };

  const handleLoginSuccess = (message) => {
    setShowCart(true);
    setShowAlert(true);
    setAlertMessage(message);
    queryClient.resetQueries();
  };

  const handleLoginFail = (message) => {
    setAlertMessage(message);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleCheckout = () => {
    if (isLoggedIn) {
      if (outOfStockItems.length > 0) {
        setOutOfStockOpen(true);
      } else {
        beginCheckout_GA(data);
        navigate(`/checkout`);
      }
    } else {
      setShowCart(false);
    }
  };

  const closeOutOfStockDialog = () => {
    setOutOfStockOpen(false);
  };

  const getItemsButtonText = () => {
    return (
      `${data?.items?.length ? data?.orderTotal?.numItems : 0} ` +
      (data?.orderTotal?.numItems === 1
        ? CART_ITEM_MESSAGE
        : CART_ITEM_MESSAGE_PLURAL)
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={true}
        PaperProps={{
          sx: {
            width: { xs: "100%", md: "50%", lg: "40%", xl: "30%" },
          },
        }}
        SlideProps={{ className: "overlay-slide-open" }}
        onBackdropClick={handleBackdropClick}
        onKeyDown={handleKeyDown}
        disableRestoreFocus={true}
      >
        <Grid
          item
          m={2}
          xs={12}
          sx={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <IconButton onClick={handleBackdropClick} className="cart-close">
            <CloseIcon></CloseIcon>
          </IconButton>
          <Grid item mt={2}>
            <h4 align="center" style={{ marginBottom: "1rem" }}>
              {CART}
            </h4>
          </Grid>
          <Grid item mt={2}>
            {data?.couponEligibilityDetails && (
              <CouponHint couponHintDetails={data.couponEligibilityDetails} />
            )}
          </Grid>
          <Grid item mt={2}>
            {showCart && (
              <div
                style={{
                  backgroundColor: "var(--dark-blue)",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{ color: "var(--white)", paddingBottom: "unset" }}
                >
                  {getItemsButtonText()}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item sx={{ width: "100%", height: "100%" }}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {showCart && cartItems}
              {!showCart && (
                <CartLogin
                  createExpressAccount={handleExpressAccount}
                  loginSuccess={handleLoginSuccess}
                  loginFail={handleLoginFail}
                />
              )}
              <Grid item>
                {showCart && (
                  <Grid item xs={12} align="right">
                    <RewardsOnOrder
                      rewardPoints={rewardsData?.pointsReceive}
                      isFetching={isFetching}
                      shouldConsider={true}
                    />
                  </Grid>
                )}
                {showCart && (
                  <div
                    style={{
                      backgroundColor: "var(--darker-gray)",
                      height: "3rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginTop:
                        rewardsData?.pointsReceiveOnCheckout > 0 ? 0 : "1rem",
                    }}
                  >
                    <Typography
                      color={"var(--blue)"}
                      paddingBottom="unset"
                      mr={3}
                    >
                      {`${SUB_TOTAL}:\u00A0\u00A0 ${
                        data && data.totalAmountWithoutTax
                          ? `$${getFormattedPrice(
                              data.totalAmountWithoutTax,
                              2
                            )}`
                          : "$0.00"
                      }`}
                    </Typography>
                  </div>
                )}
                {showCart && (
                  <CartActionButton
                    buttonText={CHECKOUT}
                    btnStyle={{ backgroundColor: "var(--dark-blue)" }}
                    actionButtonClicked={handleCheckout}
                  />
                )}
                <Grid height="1rem" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
      <Snackbar open={showAlert} autoHideDuration={4000} onClose={handleClose}>
        <Alert severity={isError ? "error" : "success"}>
          {isError ? error?.message : alertMessage}
        </Alert>
      </Snackbar>
      {outOfStockOpen && outOfStockItems?.length > 0 && (
        <OutOfStockDialog
          items={outOfStockItems}
          handleClose={closeOutOfStockDialog}
          handleOutOfStockSuccess={handleOutOfStockSuccess}
        />
      )}
    </>
  );
}

export default CartMain;
